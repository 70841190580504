import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VCardTitle,
        { attrs: { "primary-title": "" } },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { staticClass: "pt-4 pl-4", attrs: { xs8: "" } },
                [
                  _c(VLayout, { staticClass: "H5-Primary-Left" }, [
                    _vm._v("Site Permissions")
                  ]),
                  _c(
                    VLayout,
                    [
                      _c(VTextField, {
                        staticClass: "Brand-Not-Selected-Error",
                        attrs: {
                          rules: _vm.permissionRules(
                            _vm.selectedBrands.length ||
                              _vm.selectedSites.length
                          ),
                          value:
                            _vm.selectedBrands.length ||
                            _vm.selectedSites.length
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { staticStyle: { cursor: "pointer" }, attrs: { xs4: "" } },
                [
                  _vm.selectedSites.length > 0
                    ? _c(
                        VLayout,
                        {
                          staticClass: "mt-3 mr-5 noselect",
                          attrs: { "justify-end": "" },
                          on: {
                            click: function($event) {
                              _vm.isAllCollapsed = !_vm.isAllCollapsed
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "Body-2-Primary-On-Surface-Left" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.isAllCollapsed
                                      ? "Expand All"
                                      : "Collapse All"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(VIcon, { staticStyle: { color: "#0d73d8" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.isAllCollapsed
                                  ? "mdi-chevron-down"
                                  : "mdi-chevron-up"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { staticClass: "v-card-content" },
        [
          _c(VFlex, { staticClass: "py-0", attrs: { xs12: "" } }, [
            _c("div", { staticClass: "local-menu-group-blurb" }, [
              _c("p", { staticClass: "ma-0" }, [
                _vm._v(
                  "\n          Users assigned the role of Site Operator for their respective sites will also gain\n          access to the Local Menu Groups associated with those sites.\n        "
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        VLayout,
        { staticClass: "v-card-content" },
        [
          _c(
            VFlex,
            [
              _vm.selectedSites.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        VLayout,
                        [
                          _c(
                            VFlex,
                            { attrs: { xs8: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Search Sites",
                                  "prepend-icon": "mdi-magnify",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          ),
                          _c(VFlex, { attrs: { xs4: "" } })
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticStyle: { "min-height": "450px" } },
                        [
                          _c(
                            VFlex,
                            _vm._l(
                              _vm.orderBy(
                                _vm.filterBy(
                                  _vm.selectedSites,
                                  _vm.search,
                                  "name"
                                ),
                                "name"
                              ),
                              function(site, sIndex) {
                                return _c(
                                  VLayout,
                                  {
                                    key: sIndex,
                                    staticClass:
                                      "Site-Permissions-Container px-3 mt-2 mb-2 noselect"
                                  },
                                  [
                                    _c(
                                      VFlex,
                                      [
                                        _c(
                                          VLayout,
                                          [
                                            _c(
                                              VFlex,
                                              {
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.expand(site)
                                                  }
                                                }
                                              },
                                              [
                                                _c(VLayout, [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "H6-Secondary-Left"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            (site.label &&
                                                              site.label.en) ||
                                                              "Untitled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]),
                                                _c(
                                                  VLayout,
                                                  [
                                                    _c(VTextField, {
                                                      staticClass:
                                                        "Brand-Not-Selected-Error",
                                                      attrs: {
                                                        rules: _vm.permissionRules(
                                                          _vm.getSelectedBrands(
                                                            site
                                                          ).length
                                                        ),
                                                        value: _vm.getSelectedBrands(
                                                          site
                                                        ).length
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              VFlex,
                                              { attrs: { xs4: "" } },
                                              [
                                                _c(
                                                  VLayout,
                                                  {
                                                    attrs: { "justify-end": "" }
                                                  },
                                                  [
                                                    _c(
                                                      VFlex,
                                                      [
                                                        _c(
                                                          VLayout,
                                                          {
                                                            attrs: {
                                                              "justify-end": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              VBtn,
                                                              {
                                                                attrs: {
                                                                  flat: "",
                                                                  color:
                                                                    "#090c9b"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeSite(
                                                                      site.id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  VIcon,
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      left: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-delete-outline"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "REMOVE\n                          "
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      VFlex,
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.expand(
                                                              site
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          VLayout,
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                              "margin-top":
                                                                "0px"
                                                            },
                                                            attrs: {
                                                              "justify-center":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            _c(VIcon, [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .expansionPanelValues[
                                                                    site.id
                                                                  ]
                                                                    ? "mdi-chevron-up"
                                                                    : "mdi-chevron-down"
                                                                )
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        !_vm.expansionPanelValues[site.id]
                                          ? _c(
                                              VLayout,
                                              {
                                                staticClass: "my-1",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.expand(site)
                                                  }
                                                }
                                              },
                                              [
                                                _c(VFlex, [
                                                  _vm._v(
                                                    "\n                    User has access to \n                    "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        site.totalSelected
                                                      ) +
                                                        "/" +
                                                        _vm._s(site.total) +
                                                        " brands"
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    "\n                     at " +
                                                      _vm._s(
                                                        (site.label &&
                                                          site.label.en) ||
                                                          "Untitled"
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _c(
                                              VLayout,
                                              {
                                                staticClass: "pl-2",
                                                attrs: { "justify-start": "" }
                                              },
                                              [
                                                _c(
                                                  VFlex,
                                                  [
                                                    _c(
                                                      VLayout,
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "-20px",
                                                          "margin-bottom":
                                                            "-15px"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.toggleBrandsSelection(
                                                              site.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(VCheckbox, {
                                                          attrs: {
                                                            label: "Select All",
                                                            value: true
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedSitesCache[
                                                                site.id
                                                              ].isAllSelected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .selectedSitesCache[
                                                                  site.id
                                                                ],
                                                                "isAllSelected",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "selectedSitesCache[site.id].isAllSelected"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      VLayout,
                                                      [
                                                        _c(VTextField, {
                                                          staticClass:
                                                            "Brand-Not-Selected-Error",
                                                          attrs: {
                                                            rules: _vm.appSelectionRules(
                                                              site.totalSelected ||
                                                                site.isAllSelected
                                                            ),
                                                            value: Object.keys(
                                                              _vm
                                                                .selectedSitesCache[
                                                                site.id
                                                              ].brands
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      VLayout,
                                                      [
                                                        _c(
                                                          VList,
                                                          _vm._l(
                                                            _vm.getBrands(
                                                              site.id
                                                            ),
                                                            function(
                                                              brand,
                                                              bIndex
                                                            ) {
                                                              return _c(
                                                                VListTile,
                                                                {
                                                                  key: bIndex,
                                                                  attrs: {
                                                                    label:
                                                                      brand.name
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    VListTileAction,
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.toggleBrandSelection(
                                                                                site.id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VCheckbox,
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  brand.name +
                                                                                  "  - " +
                                                                                  _vm
                                                                                    .brandDescMap[
                                                                                    brand
                                                                                      .id
                                                                                  ]
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .selectedSitesCache[
                                                                                    site
                                                                                      .id
                                                                                  ]
                                                                                    .brands[
                                                                                    brand
                                                                                      .id
                                                                                  ]
                                                                                    .isSelected,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .selectedSitesCache[
                                                                                      site
                                                                                        .id
                                                                                    ]
                                                                                      .brands[
                                                                                      brand
                                                                                        .id
                                                                                    ],
                                                                                    "isSelected",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "selectedSitesCache[site.id].brands[brand.id].isSelected"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        {
                          staticClass: "pl-4 mt-4 mb-2 mr-5",
                          attrs: { "justify-start": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "Body-2-Primary-On-Surface-Left noselect",
                              staticStyle: {
                                "text-decoration": "underline",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  _vm.dialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            Edit Site Permissions\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "Body-1-Primary-Left Permissions-Link",
                      on: {
                        click: function($event) {
                          _vm.dialog = true
                        }
                      }
                    },
                    [
                      _vm.selectedSites.length === 0
                        ? _c("div", [_vm._v("Choose Sites")])
                        : _vm._e()
                    ]
                  ),
              _c("permissions-dialog", {
                attrs: {
                  dialog: _vm.dialog,
                  selectedSites: _vm.selectedSitesCache,
                  selectedRole: _vm.selectedRole,
                  selectedGroup: _vm.selectedGroup
                },
                on: {
                  "update:dialog": function($event) {
                    _vm.dialog = $event
                  },
                  "update:selectedSites": function($event) {
                    _vm.selectedSitesCache = $event
                  },
                  "update:selected-sites": function($event) {
                    _vm.selectedSitesCache = $event
                  },
                  "update:selectedRole": function($event) {
                    _vm.selectedRole = $event
                  },
                  "update:selected-role": function($event) {
                    _vm.selectedRole = $event
                  },
                  "update:selectedGroup": function($event) {
                    _vm.selectedGroup = $event
                  },
                  "update:selected-group": function($event) {
                    _vm.selectedGroup = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }