<template>
  <v-card>
    <v-card-title primary-title>
      <v-layout>
        <v-flex xs8 class="pt-4 pl-4">
          <v-layout class="H5-Primary-Left">Site Permissions</v-layout>
          <v-layout>
            <v-text-field
              class="Brand-Not-Selected-Error"
              :rules="permissionRules(selectedBrands.length || selectedSites.length)"
              :value="selectedBrands.length || selectedSites.length"
            />
          </v-layout>
        </v-flex>
        <v-flex xs4 style="cursor: pointer;">
          <v-layout
            v-if="selectedSites.length > 0"
            justify-end
            class="mt-3 mr-5 noselect"
            v-on:click="isAllCollapsed = !isAllCollapsed"
          >
            <div class="Body-2-Primary-On-Surface-Left">
              {{ isAllCollapsed ? 'Expand All' : 'Collapse All' }}
            </div>
            <v-icon style="color: #0d73d8;">{{
              isAllCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'
            }}</v-icon>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-layout class="v-card-content">
      <v-flex xs12 class="py-0">
        <div class="local-menu-group-blurb">
          <p class="ma-0">
            Users assigned the role of Site Operator for their respective sites will also gain
            access to the Local Menu Groups associated with those sites.
          </p>
        </div>
      </v-flex>
    </v-layout>
    <v-layout class="v-card-content">
      <v-flex>
        <div v-if="selectedSites.length > 0">
          <v-layout>
            <v-flex xs8>
              <v-text-field
                label="Search Sites"
                prepend-icon="mdi-magnify"
                v-model="search"
                clearable
              />
            </v-flex>
            <v-flex xs4 />
          </v-layout>
          <v-layout style="min-height: 450px;">
            <v-flex>
              <v-layout
                v-for="(site, sIndex) of orderBy(filterBy(selectedSites, search, 'name'), 'name')"
                v-bind:key="sIndex"
                class="Site-Permissions-Container px-3 mt-2 mb-2 noselect"
              >
                <v-flex>
                  <v-layout>
                    <v-flex style="cursor: pointer;" @click="expand(site)">
                      <v-layout>
                        <div class="H6-Secondary-Left">
                          {{ (site.label && site.label.en) || 'Untitled' }}
                        </div>
                      </v-layout>
                      <v-layout>
                        <v-text-field
                          class="Brand-Not-Selected-Error"
                          :rules="permissionRules(getSelectedBrands(site).length)"
                          :value="getSelectedBrands(site).length"
                        />
                      </v-layout>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout justify-end>
                        <v-flex>
                          <v-layout justify-end>
                            <v-btn flat color="#090c9b" v-on:click="removeSite(site.id)">
                              <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
                            </v-btn>
                          </v-layout>
                        </v-flex>
                        <v-flex @click="expand(site)">
                          <v-layout style="cursor: pointer; margin-top: 0px;" justify-center>
                            <v-icon>{{
                              expansionPanelValues[site.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'
                            }}</v-icon>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    style="cursor: pointer;"
                    @click="expand(site)"
                    class="my-1"
                    v-if="!expansionPanelValues[site.id]"
                  >
                    <v-flex>
                      User has access to&nbsp;
                      <b>{{ site.totalSelected }}/{{ site.total }}&nbsp;brands</b>
                      &nbsp;at {{ (site.label && site.label.en) || 'Untitled' }}
                    </v-flex>
                  </v-layout>
                  <v-layout class="pl-2" v-else justify-start>
                    <v-flex>
                      <v-layout
                        v-on:click="toggleBrandsSelection(site.id)"
                        style="margin-top: -20px; margin-bottom: -15px;"
                      >
                        <v-checkbox
                          label="Select All"
                          v-model="selectedSitesCache[site.id].isAllSelected"
                          :value="true"
                        />
                      </v-layout>
                      <v-layout>
                        <v-text-field
                          class="Brand-Not-Selected-Error"
                          :rules="appSelectionRules(site.totalSelected || site.isAllSelected)"
                          :value="Object.keys(selectedSitesCache[site.id].brands)"
                        />
                      </v-layout>
                      <v-layout>
                        <v-list>
                          <v-list-tile
                            v-for="(brand, bIndex) of getBrands(site.id)"
                            v-bind:key="bIndex"
                            :label="brand.name"
                          >
                            <v-list-tile-action>
                              <div v-on:click="toggleBrandSelection(site.id)">
                                <v-checkbox
                                  v-model="selectedSitesCache[site.id].brands[brand.id].isSelected"
                                  :label="`${brand.name}  - ${brandDescMap[brand.id]}`"
                                />
                              </div>
                            </v-list-tile-action>
                          </v-list-tile>
                        </v-list>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout justify-start class="pl-4 mt-4 mb-2 mr-5">
            <div
              class="Body-2-Primary-On-Surface-Left noselect"
              style="text-decoration: underline; cursor: pointer;"
              v-on:click="dialog = true"
            >
              Edit Site Permissions
            </div>
          </v-layout>
        </div>
        <div v-else class="Body-1-Primary-Left Permissions-Link" v-on:click="dialog = true">
          <div v-if="selectedSites.length === 0">Choose Sites</div>
        </div>
        <permissions-dialog
          :dialog.sync="dialog"
          :selectedSites.sync="selectedSitesCache"
          :selectedRole.sync="selectedRole"
          :selectedGroup.sync="selectedGroup"
        />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import filters from 'vue2-filters';
import cloneDeep from 'lodash/cloneDeep';

import PermissionsDialog from './sitesSelectorDialog';

export default {
  mixins: [filters.mixin],
  components: {
    PermissionsDialog,
  },
  props: ['permissions', 'selectedRole', 'permissionsStatic', 'selectedGroup'],
  data: () => ({
    dialog: false,
    selectedSitesCache: {},
    search: '',
    brandDescMap: {},
    isAllCollapsed: true,
    permissionScopesProxy: [],
    expansionPanelValues: {},
    appSelectionRules: (n) => [() => !!n || 'Please select at least 1 brand'],
    permissionRules: (n) => [() => !!n || 'Please select at least 1 brand'],
  }),
  computed: {
    ...mapGetters('splitio', ['isFeatureOn']),
    selectedSites() {
      const sites =
        Object.keys(this.selectedSitesCache).map((siteId) => {
          let totalSelected = 0;
          let total = 0;
          const site = this.selectedSitesCache[siteId];
          Object.keys(site.brands).forEach((b) => {
            if (site.brands[b].isSelected) totalSelected += 1;
            total += 1;
          });
          return { ...site, totalSelected, total };
        }) || [];
      return sites;
    },
    selectedBrands() {
      try {
        return this.selectedSites.map((s) => s.brands && Object.keys(s.brands)).flat();
      } catch {
        return [];
      }
    },
    ...mapState('sites', {
      siteMap: (state) => state.siteMap,
      brandMap: (state) => state.brandMap,
    }),
  },
  watch: {
    isAllCollapsed(c) {
      Object.keys(this.selectedSitesCache).forEach((s) => {
        this.$set(this.expansionPanelValues, s, !c);
      });
    },
    selectedSites(s) {
      if (this.selectedRole === 'runner_role' && s) {
        this.$emit('update:selectedGroup', s[0] && s[0].id);
      }
      const extraPermissions = this.permissions.scopes.filter((scope) => !scope.includes('_role'));
      const scopes = [];
      const assignedRole = this.selectedRole;
      s.forEach((site) => {
        if (site.total === site.totalSelected && site.isAllSelected) {
          // all brands are selected
          scopes.push(`${assignedRole}:group:${site.id}`);
        } else {
          this.getBrands(site.id).forEach((b) => {
            if (b.isSelected) scopes.push(`${assignedRole}:brand:${b.id}`);
          });
        }
      });
      if (scopes.length === 0) scopes.push(`${this.selectedRole}:*:*`);
      scopes.push(...extraPermissions);
      scopes.sort();
      this.$emit('update:permissions', { scopes });
    },
    permissionsStatic(p) {
      // do the backwards mapping
      if (Object.keys(this.siteMap).length !== 0) {
        this.mapPropsToCache(p);
      }
    },
  },
  methods: {
    async expand(site) {
      await Promise.all(
        Object.keys(this.siteMap[site.id].brands).map(async (id) => {
          try {
            const { data } = await this.api.get(`/config/public/${id}`);
            this.brandDescMap[id] = data.brand_location_description || 'no description found';
          } catch {
            this.brandDescMap[id] = 'no description found';
          }
        }),
      );
      this.$set(this.expansionPanelValues, site.id, !this.expansionPanelValues[site.id]);
    },
    async removeSite(id) {
      const site = this.selectedSitesCache[id];
      const siteName = (site.label && site.label.en) || 'Untitled';
      const input = await this.$confirm({
        title: 'Remove Site from Permissions?',
        message: `Are you sure you want to remove ${siteName} from this user's site permission?`,
        buttonTrueText: 'REMOVE',
        buttonFalseText: 'CANCEL',
      });
      if (input) {
        this.selectedSitesCache[id] = undefined;
        delete this.selectedSitesCache[id];
      }
    },
    getBrands(siteId) {
      const site = this.selectedSitesCache[siteId];
      const { brands } = site;
      return Object.keys(brands).map((b) => brands[b]) || [];
    },
    toggleBrandSelection(siteId) {
      const site = this.selectedSitesCache[siteId];
      this.setSite(site.id, { ...site, isAllSelected: false });
    },
    toggleBrandsSelection(siteId) {
      const site = this.selectedSitesCache[siteId];
      const { brands } = site;
      Object.keys(brands).forEach((b) => {
        const brand = brands[b];
        this.setBrand(site.id, { ...brand, isSelected: site.isAllSelected });
      });
    },
    setBrand(siteId, brand) {
      const site = this.selectedSitesCache[siteId];
      this.$set(this.selectedSitesCache, siteId, {
        ...site,
        brands: {
          ...site.brands,
          [brand.id]: {
            ...brand,
          },
        },
      });
    },
    setSite(siteId, site) {
      this.$set(this.selectedSitesCache, siteId, site);
    },
    mapPropsToCache(p) {
      // do the backwards mapping
      const selectedSitesCache = {};
      if (p.scopes && Array.isArray(p.scopes)) {
        p.scopes.sort().forEach((s) => {
          const roleInfo = s.split(':');
          const permissionType = roleInfo[1];
          const id = roleInfo[2];
          if (permissionType === 'group' && id !== '*' && !!this.siteMap[id]) {
            if (!selectedSitesCache[id]) selectedSitesCache[id] = cloneDeep(this.siteMap[id]);
            selectedSitesCache[id].isAllSelected = true;
            const { brands } = selectedSitesCache[id];
            Object.keys(brands).forEach((b) => {
              brands[b].isSelected = true;
            });
          } else if (permissionType === 'brand' && id !== '*') {
            // find corresponding site
            const siteId = this.brandMap[id].parentSite.id;
            if (!selectedSitesCache[siteId]) {
              selectedSitesCache[siteId] = cloneDeep(this.siteMap[siteId]);
              Object.keys(selectedSitesCache[siteId].brands).forEach((brandId) => {
                selectedSitesCache[siteId].brands[brandId].isSelected = false;
              });
            }
            if (!selectedSitesCache[siteId].brands) selectedSitesCache[siteId].brands = {};
            selectedSitesCache[siteId].brands[id] = cloneDeep({
              ...this.brandMap[id],
              isSelected: true,
            });
          }
        });
      }
      Object.keys(selectedSitesCache).forEach((s) => {
        selectedSitesCache[s].isAllSelected =
          p.scopes.find((scope) => scope.includes(`group:${s}`)) &&
          Object.values(selectedSitesCache[s].brands).every((b) => b.isSelected);
      });
      this.$set(this, 'selectedSitesCache', selectedSitesCache);
    },
    getSelectedBrands(site) {
      try {
        return Object.keys(site.brands).map((b) => site.brands[b]);
      } catch {
        return [];
      }
    },
  },
  mounted() {
    if (Object.keys(this.siteMap).length !== 0 && Object.keys(this.brandMap).length !== 0) {
      this.mapPropsToCache(this.permissionsStatic);
    }
  },
};
</script>

<style>
.Permissions-Link {
  text-decoration: underline;
  cursor: pointer;
}
.Site-Permissions-Container {
  border-bottom: 1px solid lightgrey;
}

.Brand-Not-Selected-Error {
  padding: 0 !important;
}

.Brand-Not-Selected-Error > .v-input__control > .v-input__slot {
  display: none !important;
}
.local-menu-group-blurb {
  border: solid 1px #0d73d8;
  padding: 1rem;
  border-radius: 2px;
}
</style>
