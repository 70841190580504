import { render, staticRenderFns } from "./menu_scheduler.vue?vue&type=template&id=db163a48&scoped=true"
import script from "./menu_scheduler.vue?vue&type=script&lang=js"
export * from "./menu_scheduler.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db163a48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4028959781/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db163a48')) {
      api.createRecord('db163a48', component.options)
    } else {
      api.reload('db163a48', component.options)
    }
    module.hot.accept("./menu_scheduler.vue?vue&type=template&id=db163a48&scoped=true", function () {
      api.rerender('db163a48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/menu_scheduler.vue"
export default component.exports