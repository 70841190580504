import { render, staticRenderFns } from "./promoDetails.vue?vue&type=template&id=88aa5c3c"
import script from "./promoDetails.vue?vue&type=script&lang=js"
export * from "./promoDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4042112146/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('88aa5c3c')) {
      api.createRecord('88aa5c3c', component.options)
    } else {
      api.reload('88aa5c3c', component.options)
    }
    module.hot.accept("./promoDetails.vue?vue&type=template&id=88aa5c3c", function () {
      api.rerender('88aa5c3c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/promo/components/promoDetails.vue"
export default component.exports